export const notices = [
  // 생성월, 일이 한자리수라면 created_at: "2024-03-02" 꼴로 입력해야 사파리에서 제대로 표시되지 않는 경우가 발생하지 않음.
  {
    created_at: "2024-10-07",
    title: "2024년도 몰입캠프 겨울학기 모집",
    content: `안녕하세요.

KAIST와 MOU 맺은 국내 대학 학부생을 대상으로 **<2024년도 몰입캠프 겨울학기>** 모집을 시작합니다.

**모집 기간은 10월 13일(일) ~ 11월 3일(일) 자정까지(밤 12시)이고**, 모집 기간 이후에는 지원을 받지않습니다.

관심있는 학생들의 많은 지원 바랍니다. 감사합니다.

문의 사항: madcamp.kaist@gmail.com
`,
  },
  {
    created_at: "2024-05-13",
    title: "2024년도 몰입캠프 여름학기 참가 결과 발표",
    content: `안녕하세요.

2024년도 KAIST 몰입캠프 여름학기에 지원해 주셔서 감사합니다.

서류 심사 결과에 대해, 지원하신 모든 분들께 개별 이메일로 공지가 되었습니다. 메일을 확인해 주시고, 혹시라도 메일을 받지 못하신 분은 아래 연락처로 문의해 주세요.

**문의** : 몰입캠프 운영팀 (madcamp.kaist@gmail.com)

보내주신 성원에 감사드리며, 이번에 아쉽게 참가하지 못하신 분들은 다음 학기 개설 시에 뵐 수 있기를 기대하겠습니다.

감사합니다.  
`,
  },
  {
    created_at: "2024-03-31",
    title: "2024년도 몰입캠프 여름학기 모집",
    content: `안녕하세요.

KAIST와 MOU 맺은 국내 대학 학부생을 대상으로 **<2024년도 몰입캠프 여름학기>** 모집을 시작합니다.

**모집 기간은 4월 7일(일) ~ 4월 28일(일) 자정까지(밤 12시)이고**, 모집 기간 이후에는 지원을 받지않습니다.

관심있는 학생들의 많은 지원 바랍니다. 감사합니다.

문의 사항: madcamp.kaist@gmail.com
`,
  },
  {
    created_at: "2023-11-15",
    title: "2023년도 몰입캠프 겨울학기 참가 결과 발표",
    content: `안녕하세요.

2023년도 KAIST 몰입캠프 겨울학기에 지원해 주셔서 감사합니다.

서류 심사 결과에 대해, 지원하신 모든 분들께 개별 이메일로 공지가 되었습니다. 메일을 확인해 주시고, 혹시라도 메일을 받지 못하신 분은 아래 연락처로 문의해 주세요.

**문의** : 몰입캠프 운영팀 (madcamp.kaist@gmail.com)

보내주신 성원에 감사드리며, 이번에 아쉽게 참가하지 못하신 분들은 다음 학기 개설 시에 뵐 수 있기를 기대하겠습니다.

감사합니다.  
`,
  },

  {
    created_at: "2023-10-25",
    title: "2023년도 몰입캠프 겨울학기 모집",
    content: `안녕하세요.

KAIST와 MOU 맺은 국내 대학 학부생을 대상으로 **<2023년도 몰입캠프 겨울학기>** 모집을 시작합니다.

**모집 기간은 10월 26일(목) ~ 11월 8일(수) 자정까지(밤 12시)이고**, 모집 기간 이후에는 지원을 받지않습니다.

문의 사항: madcamp.kaist@gmail.com
`,
  },

  {
    created_at: "2023-05-17",
    title: "2023년도 몰입캠프 여름학기 참가 결과 발표",
    content: `안녕하세요.

2023년도 KAIST 몰입캠프 여름학기에 지원해 주셔서 감사합니다.

서류 심사 결과에 대해, 지원하신 모든 분들께 개별 이메일로 공지가 되었습니다. 메일을 확인해 주시고, 혹시라도 메일을 받지 못하신 분은 아래 연락처로 문의해 주세요.

**문의** : 몰입캠프 운영팀 (madcamp.kaist@gmail.com)

보내주신 성원에 감사드리며, 이번에 아쉽게 참가하지 못하신 분들은 다음 학기 개설 시에 뵐 수 있기를 기대하겠습니다.

감사합니다.  
`,
  },

  {
    created_at: "2023-04-24",
    title: "2023년도 몰입캠프 여름학기 모집",
    content: `안녕하세요.

KAIST와 MOU 맺은 국내 대학 학부생을 대상으로 **<2023년도 몰입캠프 여름학기>** 모집을 시작합니다.

**모집 기간은 4월 24일(월) ~ 5월 7일(일) 자정까지(밤 12시)이고**, 모집 기간 이후에는 지원을 받지않습니다.

방역에 대한 세부 사항은 KAIST 코로나19 대책반 방침에 따라 운영될 예정이니, 참가하는 학생들의 협조를 부탁드립니다.

관심있는 학생들의 많은 지원 바랍니다. 감사합니다.

문의 사항: madcamp.kaist@gmail.com
`,
  },

  {
    created_at: "2022-10-06",
    title: "2022년 몰입캠프 겨울학기 미개설 공지",
    content: `안녕하세요. 몰입캠프 운영진입니다.

2022년 겨울학기 몰입캠프를 기다리셨을 여러분께 아쉬운 소식을 전하게 되었습니다.

2015년 겨울학기부터 쉬지 않고 매 계절학기에 개설했던,
**몰입캠프 과목을 2022년 겨울학기에는 개설하지 않기로 했습니다.**
몰입캠프의 방향과 변화를 모색하고자 잠시 쉬어 가는 시간이니, 여러분의 양해를 부탁드립니다.

이후 몰입캠프 관련 내용은 결정 사항이 명확해지면 홈페이지를 통해 공지하겠습니다.
몰입캠프에 대한 관심에 진심으로 감사드립니다. 
`,
  },

  {
    created_at: "2022-05-17",
    title: "2022년도 몰입캠프 여름학기 참가 결과 발표 ",
    content: `안녕하세요.

2022년도 KAIST 몰입캠프 여름학기에 지원해 주셔서 감사합니다.

서류 심사 결과에 대해, 지원하신 모든 분들께 개별 이메일로 공지가 되었습니다. 메일을 확인해 주시고, 혹시라도 메일을 받지 못하신 분은 아래 연락처로 문의해 주세요.

문의: 몰입캠프 행정담당 최효영 (cs496.ipc@gmail.com)

보내주신 성원에 감사드리며, 이번에 아쉽게 참가하지 못하신 분들은 다음 학기 개설 시에 뵐 수 있기를 기대하겠습니다.

감사합니다.  
`,
  },

  {
    created_at: "2022-04-25",
    title: "2022년도 몰입캠프 여름학기 모집",
    content: `안녕하세요.

KAIST와 MOU 맺은 국내 대학 학부생을 대상으로 **<2022년도 몰입캠프 여름학기>** 모집을 시작합니다.

**모집 기간은 4월 25일(월) ~ 5월 8일(일) 자정까지(밤 12시)이고**, 모집 기간 이후에는 지원을 받지않습니다.

방역에 대한 세부 사항은 KAIST 코로나19 대책반 방침에 따라 운영될 예정이니, 참가하는 학생들의 협조를 부탁드립니다.

**추후 코로나19 상황에 따라 몰입캠프 일정은 변동될 수 있으며**, 변동 사항 발생 시 홈페이지 또는 별도로 공지하겠습니다.

관심있는 학생들의 많은 지원 바랍니다. 감사합니다.

문의 사항: cs496.ipc@gmail.com

`,
  },

  {
    created_at: "2021-11-17",
    title: "2021년도 몰입캠프 겨울학기 참가 결과 발표 ",
    content: `안녕하세요.

2021년도 KAIST 몰입캠프 겨울학기에 지원해 주셔서 감사합니다.

서류 심사 결과에 대해, 지원하신 모든 분들께 개별 이메일로 공지가 되었습니다. 메일을 확인해 주시고, 혹시라도 메일을 받지 못하신 분은 아래 연락처로 문의해 주세요.

문의: 몰입캠프 행정담당 최효영 (cs496.ipc@gmail.com)

보내주신 성원에 감사드리며, 이번에 아쉽게 참가하지 못하신 분들은 다음 학기 개설 시에 뵐 수 있기를 기대하겠습니다.

감사합니다. 

`,
  },

  {
    created_at: "2021-10-25",
    title:
      "2021년도 몰입캠프 겨울학기 모집(코로나19 상황에 따라 추후 변동 가능) ",
    content: `안녕하세요.

KAIST와 MOU 맺은 국내 대학 학부생을 대상으로 **<2021년도 몰입캠프 겨울학기>** 모집을 시작합니다.

**모집 기간은 10월 25일(월) ~ 11월 7일(일) 자정까지(밤 12시)**이고, 모집 기간 이후에는 지원을 받지않습니다.

방역에 대한 세부 사항은 KAIST 코로나19 대책반 방침에 따라 운영될 예정이니, 참가하는 학생들의 협조를 부탁드립니다. 

**추후 코로나19 상황에 따라 몰입캠프 개설 및 일정은 변동될 수 있으며**, 변동 사항 발생 시 홈페이지 또는 별도로 공지하겠습니다. 

관심있는 학생들의 많은 지원 바랍니다.
감사합니다.

문의 사항: cs496.ipc@gmail.com

`,
  },

  {
    created_at: "2021-05-20",
    title: "2021년도 몰입캠프 여름학기 참가 결과 발표 ",
    content: `안녕하세요.

2021년도 KAIST 몰입캠프 여름학기에 지원해 주셔서 감사합니다.

서류 심사 결과에 대해, 지원하신 모든 분들께 **개별 이메일로 공지**가 되었습니다. 메일을 확인해 주시고, 혹시라도 메일을 받지 못하신 분은 아래 연락처로 문의해 주세요.

문의: 몰입캠프 행정담당 최효영 (cs496.ipc@gmail.com)

보내주신 성원에 감사드리며, 이번에 아쉽게 참가하지 못하신 분들은 다음 학기 개설 시에 뵐 수 있기를 기대하겠습니다.

감사합니다. 

`,
  },

  {
    created_at: "2021-05-10",
    title: "2021년도 몰입캠프 여름학기 모집 마감 ",
    content: `안녕하세요. 

2021년도 KAIST 몰입캠프 여름학기 모집이 마감되었습니다. 

참가자 발표는 **5/20(목)** 개별 이메일을 통하여 공지 예정입니다.
 
몰입캠프에 대한 관심과 성원에 감사드립니다.

`,
  },

  {
    created_at: "2021-04-26",
    title:
      "2021년도 몰입캠프 여름학기 모집(코로나19 상황에 따라 추후 변동 가능) ",
    content: `안녕하세요.

KAIST와 MOU 맺은 국내 대학 학부생을 대상으로 **<2021년도 몰입캠프 여름학기>** 모집을 시작합니다.

**모집 기간은 4월 26일(월) ~ 5월 9일(일) 자정까지**이고, 모집 기간 이후에는 지원을 받지않습니다.

방역에 대한 세부 사항은 KAIST 방침에 따라 운영될 예정이니, 참가하는 학생들의 협조 부탁드립니다. 

**추후 코로나19 상황에 따라 몰입캠프 개설 및 일정은 변동될 수 있으며**, 변동 사항 발생 시 홈페이지 또는 별도로 공지하겠습니다. 

문의 사항: cs496.ipc@gmail.com

몰입캠프에 대한 관심 감사드립니다.
`,
  },

  {
    created_at: "2020-11-18",
    title: "2020년도 몰입캠프 겨울학기 참가 결과 발표 ",
    content: `안녕하세요.

2020년도 KAIST 몰입캠프 겨울학기에 지원해 주셔서 감사합니다.

서류 심사 결과에 대해, 지원하신 모든 분들께 **개별 이메일로 공지**가 되었습니다. 메일을 확인해 주시고, 혹시라도 메일을 받지 못하신 분은 아래 연락처로 문의해 주세요.

[문의]: 몰입캠프 행정담당 최효영 (cs496.ipc@gmail.com)

보내주신 성원에 감사드리며, 이번에 아쉽게 참가하지 못하신 분들은 다음 학기 개설 시에 뵐 수 있기를 기대하겠습니다.

감사합니다. 
`,
  },

  {
    created_at: "2020-11-09",
    title: "2020년도 몰입캠프 겨울학기 모집 마감",
    content: `안녕하세요. 

2020년도 KAIST 몰입캠프 겨울학기 모집이 마감되었습니다. 

참가자 발표는 **11/18(수)** 개별 이메일을 통하여 공지 예정입니다. 

몰입캠프에 대한 관심과 성원에 감사드립니다. 

`,
  },

  {
    created_at: "2020-10-26",
    title:
      "2020년도 몰입캠프 겨울학기 모집(코로나19 상황에 따라 추후 변동 가능) ",
    content: `안녕하세요.

KAIST와 MOU 맺은 국내 대학 학부생을 대상으로 **<2020년도 몰입캠프 겨울학기>** 모집을 시작합니다. 
코로나19로 인해 해외 대학은 모집하지 않고 국내 대학을 대상으로 합니다. 

모집 기간은 **10월 26일(월) ~ 11월 8일(일) 자정까지**이고, 모집 기간 이후에는 지원이 불가능합니다.

방역에 대한 세부 사항은 KAIST 방침에 따라 운영될 예정이니, 참가하는 학생들의 협조 부탁드립니다. 

**추후 코로나19 상황에 따라 몰입캠프 개설 및 일정은 변동될 수 있으며**, 변동 사항 발생 시 홈페이지 또는 별도로 공지하겠습니다. 

문의 사항: cs496.ipc@gmail.com

몰입캠프에 대한 관심 감사드립니다.

`,
  },

  {
    created_at: "2020-06-05",
    title: "2020년도 몰입캠프 여름학기 참가 결과 발표",
    content: `안녕하세요.

2020년도 KAIST 몰입캠프 여름학기에 지원해 주셔서 감사합니다.

서류 심사 결과에 대해, 지원하신 모든 분들께 개별 이메일로 공지가 되었습니다. 메일을 확인해 주시고, 혹시라도 메일을 받지 못하신 분은 아래 연락처로 문의해 주세요.

[문의]: 몰입캠프 행정담당 최효영 (cs496.ipc@gmail.com)

보내주신 성원에 감사드리며, 이번에 아쉽게 참가하지 못하신 분들은 다음 학기 개설 시에 뵐 수 있기를 기대하겠습니다.

감사합니다. 

`,
  },

  {
    created_at: "2020-06-01",
    title: "2020년도 몰입캠프 여름학기 참가자 발표 일정 연기(6/5,금)",
    content: `안녕하세요.

2020년도 몰입캠프 여름학기 참가자 발표 일정이 연기되어 공지드립니다. 

코로나19로 인한, 학교 내부 검토로 인해 참가자 발표 일정이 6월 3일(수)에서 **6월 5일(금)**로 연기되었습니다. 지원자분들께 양해의 말씀드립니다.

변동 사항 발생 시 다시 공지하겠습니다.


몰입캠프에 대한 관심 감사드립니다.
`,
  },

  {
    created_at: "2020-05-25",
    title: "2020년도 몰입캠프 여름학기 모집 마감",
    content: `안녕하세요.

2020년도 KAIST 몰입캠프 여름학기 모집이 마감되었습니다.

참가자 발표는 6월 3일(수) 개별 이메일을 통하여 공지 예정입니다. 

보내주신 성원에 감사드립니다.
`,
  },

  {
    created_at: "2020-05-07",
    title:
      "2020년도 몰입캠프 여름학기 개설 공지(코로나19 상황에 따라 추후 변동 가능)",
    content: `안녕하세요. 몰입캠프 공지를 기다려 주셔서 감사합니다. <2020년도 몰입캠프 여름학기> 개설에 대해 최종 공지드립니다. 
 
몰입캠프에 대한 학생들의 관심이 많기에, 가급적 소수 인원으로라도 진행하는 방향으로 논의를 하였습니다. 
현재 코로나19 확산세가 안정되어 가고, 교육부에서 순차적인 등교 개학을 결정함에 따라, **2020년도 몰입캠프 여름학기를 개설**하기로 결정하였습니다. 
 
모집 인원은 기존보다 축소하여 40명으로 진행되고, 지원 신청은 5/11~5/24일까지입니다. 방역에 대한 세부 사항은 KAIST 방침에 따라 운영될 예정이니, 참가하는 학생들의 협조 부탁드립니다. 
 
**추후 코로나19 상황에 따라 몰입캠프 개설 및 일정은 변동될 수 있음을 양지 바랍니다.** 변동 사항 발생 시 홈페이지 또는 별도로 공지하겠습니다. 
 
몰입캠프에 대한 관심 감사드리며, 밝고 건강한 모습으로 몰입캠프에서 뵙기를 바라겠습니다.

`,
  },

  {
    created_at: "2020-04-10",
    title: "2020년 몰입캠프 여름학기 개설 관련",
    content: `안녕하세요. 
몰입캠프에 관심을 가진 학생들이 많기에, 2020년 몰입캠프 여름학기 개설 관련하여 공지드립니다.

현재 코로나19로 인해, 기숙사 등을 활용해야 하는 몰입캠프 여름학기 개설 여부를 논의하고 있습니다.  
우선 해외 대학은 모집하지 않기로 결정하였으며, 몰입캠프 자체가 열리지 못할 가능성이 있기에, 상황을 지켜보고 있습니다. 

**몰입캠프 개최 여부는** (모집 공고 전) **5월 초에 홈페이지 게시판을 통해 다시 공지**하겠습니다.  
몰입캠프를 개설한다면, 모집 일정은 5월 둘째 주 정도에 시작할 예정이며, 캠프 기간은 기존과 같이 4.5주 계획입니다. 

몰입캠프에 대한 관심에 감사드리고, 학생들의 건강을 기원합니다.

`,
  },

  {
    created_at: "2019-11-20",
    title: "2019년 몰입캠프 겨울학기 참가 결과 발표 ",
    content: `안녕하세요.

2019년 KAIST 몰입캠프 겨울학기에 지원해 주셔서 감사합니다.

서류 심사 결과에 대해, 지원하신 모든 분들께 개별 이메일로 공지가 되었습니다. 메일을 확인해 주세요.

혹시라도 메일을 받지 못하신 분은 아래 연락처로 문의 부탁드립니다.

[문의]: 몰입캠프 행정담당 최효영 (cs496.ipc@gmail.com)

보내주신 성원에 감사드리며, 이번에 아쉽게 참가하지 못하신 분들은 다음 학기 개설 시에 다시 뵐 수 있기를 기대하겠습니다.

감사합니다. 
`,
  },

  {
    created_at: "2019-11-12",
    title: "2019년 몰입캠프 겨울학기 모집 마감",
    content: `안녕하세요.

2019년 KAIST 몰입캠프 겨울학기 모집이 11월 10일 마감되었습니다.

참가자 발표는 11월 20일(수) 개별 이메일을 통하여 공지 예정입니다. 

보내주신 성원에 진심으로 감사드립니다. 

`,
  },

  {
    created_at: "2019-10-28",
    title: "2019년 몰입캠프 겨울학기 모집 (국내 대학)",
    content: `안녕하세요.

KAIST와 MOU 맺은 국내 대학을 대상으로, <2019년 몰입캠프 겨울학기> 모집이 시작되었습니다.

**국내 대학 모집 기간 : 10월 28일(월) ~ 11월 10일(일)**

국내외 다양한 친구들과 함께, 집중 개발을 경험하고 싶으신 분은 상단의 "지원하기" 메뉴에서 지원서를 작성해 주세요.
 
국내 대학생들의 많은 관심과 지원 바랍니다.

감사합니다.

`,
  },
  {
    created_at: "2019-10-21",
    title: "2019년 몰입캠프 겨울학기 모집 (해외 대학)",
    content: `안녕하세요.

KAIST와 MOU 맺은 해외 대학을 대상으로, 2019년 몰입캠프 겨울학기 모집이 시작되었습니다.

**해외 대학 모집 기간 : 10월 21일(월) ~ 11월 3일(일)**

상단의 "지원하기" 메뉴에서 모집개요를 참고하여, 지원서를 작성해 주세요. 
해외 대학생들의 많은 관심과 지원 바랍니다.

감사합니다.
 `,
  },

  {
    created_at: "2019-05-15",
    title: " 2019년 몰입캠프 여름학기 참가 결과 발표",
    content: `안녕하세요.

2019년 KAIST 몰입캠프 여름학기에 지원해 주셔서 감사합니다.

**몰입캠프 참가생 60명과 예비후보자 6명을 선정하였습니다.**

참가 결과에 대해 지원하신 모든 분들께 메일로 공지가 되었으니, 메일을 꼭 확인해 주세요.

혹시라도 메일을 받지 못하신 분은 아래 연락처로 문의 부탁드립니다.

**[문의]**: 몰입캠프 행정담당 최효영 (cs496.ipc@gmail.com)

보내주신 성원에 감사드리며, 이번에 아쉽게 참가하지 못하신 분들은 다음 학기에 다시 뵐 수 있기를 기대하겠습니다.

감사합니다.
 `,
  },

  {
    created_at: "2019-05-07",
    title: "2019년 몰입캠프 여름학기 모집 마감",
    content: `안녕하세요.

2019년 KAIST 몰입캠프 여름학기 모집이 5월 6일 마감되었습니다.

참가자 발표는 **해외 대학은 5월 13일(월)**, **국내 대학은 5월 15일(수)** 개별 이메일을 통하여 공지 예정입니다.

이번에 아쉽게 지원하지 못하신 분들께서는 겨울학기 개설 시에 지원해주세요.

보내주신 성원에 감사드립니다. 
 `,
  },

  {
    created_at: "2019-04-22",
    title: "2019년 몰입캠프 여름학기 모집 (국내 대학)",
    content: `안녕하세요.

KAIST와 MOU 맺은 국내 대학을 대상으로, <2019년 몰입캠프 여름학기> 모집이 시작되었습니다.

**국내 대학 모집 기간 : 4월 22일(월) ~ 5월 06일(월)**

국내외 다양한 친구들과 함께 팀워크를 이루어 집중 개발을 경험하고 싶으신 분은 상단의 "지원하기" 메뉴에서 지원서를 작성해 주세요. 

국내 대학생들의 많은 관심과 지원 바랍니다.

감사합니다.
 `,
  },

  {
    created_at: "2019-04-15",
    title: "2019년 몰입캠프 여름학기 모집 (해외 대학)",
    content: `안녕하세요.

KAIST와 MOU 맺은 해외 대학을 대상으로, <2019년 몰입캠프 여름학기> 모집이 시작되었습니다.

**해외 대학 모집 기간 : 4월 15일(월) ~ 4월 28일(일)**

국내외 다양한 친구들과 팀워크를 이루어, 집중 개발을 경험하고 싶으신 분은 상단의 "지원하기" 메뉴에서 지원서를 작성해 주세요. 

해외 대학생들의 많은 관심과 지원 바랍니다.

감사합니다.

 `,
  },

  {
    title: "2018년 몰입캠프 겨울학기 참가 결과 발표",
    created_at: "2018-11-14",
    content: `안녕하세요.

2018년 KAIST 몰입캠프 겨울학기에 지원해 주셔서 감사합니다.

**몰입캠프 참가생 60명과 예비후보자 6명을 선정하였습니다.**

지원하신 모든 분들께, 참가 결과에 대해 메일로 공지가 되었으니, 메일을 꼭 확인해 주세요.

혹시라도 메일을 받지 못하신 분은 아래 연락처로 문의 부탁드립니다.

몰입캠프 행정담당 최효영 (cs496.ipc@gmail.com)




감사합니다. 
 `,
  },

  {
    title: "2018년 몰입캠프 겨울학기 모집 마감",
    created_at: "2018-11-08",
    content: `안녕하세요.

**2018년 KAIST 몰입캠프 겨울학기 모집이 11월 6일 마감되었습니다.**

참가자 결과 발표는 11월 14일(수) 예정입니다. 

이번에 아쉽게 지원하신 못하신 분들께서는 내년 여름학기 개설 시 지원해주세요. 

보내주신 성원에 감사드립니다. `,
  },

  {
    title: "2018년 몰입캠프 겨울학기 모집 (국내 대학)",
    created_at: "2018-10-22",
    content: `안녕하세요.

KAIST와 MOU 맺은 국내 대학을 대상으로, 2018년 몰입캠프 겨울학기 모집 공고가 시작되었습니다.

**모집 기간 :  10월 22일(월) ~ 11월 06일(화)**


상단의 "지원하기" 메뉴에서 모집 개요를 참고하시고, 지원서를 작성해 주세요.

국내 대학생들의 많은 관심과 지원 바랍니다.


감사합니다.`,
  },

  {
    title: "2018년 몰입캠프 겨울학기 모집 (해외 대학)",
    created_at: "2018-10-15",
    content: `안녕하세요.

KAIST와 MOU 맺은 해외 대학을 대상으로, 2018년 몰입캠프 겨울학기 모집 공고가 시작되었습니다.

**모집 기간 :  10월 15일(월) ~ 10월 28일(일)**

상단의 "지원하기" 메뉴에서 모집 개요를 참고하시고, 지원서를 작성해 주세요.

해외 대학생들의 많은 관심과 지원 바랍니다.


감사합니다.`,
  },

  {
    title: "몰입캠프 홈페이지가 개설되었습니다.",
    created_at: "2018-10-15",
    content: `안녕하세요.

**몰입캠프 홈페이지가 개설되었습니다.** 
      
도움 주신 분들께 진심으로 감사드리며, 학생분들의 많은 관심과 지원 바랍니다.

감사합니다.`,
  },
]
  .reverse()
  .map(({ title, created_at, content }, index) => {
    return {
      title: title,
      created_at: created_at,
      content: content,
      id: index + 8,
    };
  });
// 기존 사이트에서 인덱스가 8부터 시작하므로, consistency를 위해 8부터 시작.

export function count() {
  return notices.length;
}

export function notices_page(page) {
  return notices
    .slice()
    .reverse()
    .slice((page - 1) * 10, page * 10);
}

export function notice(id) {
  return notices[id - 8]; // 기존 사이트가 8부터 시작
}
