<template>
  <footer class="footer">
    <div class="has-text-centered">
      몰입캠프 Since 2010
    </div>
  </footer>
</template>

<style lang="scss" scoped>
.footer {
  margin-top: 4rem;
}
</style>
