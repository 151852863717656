<template>
  <div class="apply">
    <section class="hero is-light">
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <h1 class="title column is-offset-3">
              지원하기
            </h1>
          </div>
        </div>
      </div>
    </section>
    <section class="container apply-info-table">
      <div class="columns">
        <div class="column title is-3 has-text-right-tablet">
          모집개요
        </div>
        <div class="column is-6">
          <table class="table is-fullwidth">
            <tbody>
              <tr>
                <th class="subject">모집인원</th>
                <td class="description" colspan="2">80명 이내 (4개 분반)</td>
              </tr>
              <tr>
                <th class="subject">참가대상</th>
                <td class="description" colspan="2">KAIST와 MOU를 맺은 국내 대학 학부생, 2000년생까지 지원 가능</td>
              </tr>
              <tr>
                <th
                  class="subject">모집기간</th>
                <td class="description" colspan="2">
                  <time datetime="2024-10-13">2024년 10월 13일</time> - <time datetime="2024-11-3">11월 3일 (자정까지)</time><br>
                </td>
              </tr>
<!--
              <tr>
                <th class="subject">신청방법</th>
                <td colspan="2">홈페이지 <router-link class="link" to="/apply">지원하기</router-link>에서 지원서 작성</td>
              </tr>
-->
              <tr>
                <th class="subject">강의계획서</th>
                <td colspan="2"><a class="link" href="https://bit.ly/47Ojqzr">https://bit.ly/47Ojqzr</a></td>
              </tr>
              <tr>
                <th class="subject">선발방법</th>
                <td colspan="2">서류 심사</td>
              </tr>

              <tr>
                <th class="subject">선발기준</th>
                <td colspan="2">학번, 성별, 활동 경험 등의 다양성을 고려하여 선발</td>
              </tr>
              <tr>
                <th
                  class="subject">결과발표</th>
                <td colspan="2">
                  <time datetime="2024-11-11">2024년 11월 11일</time><br>
                </td>
              </tr>

              <tr>
                <th class="subject">발표방법</th>
                <td colspan="2">개별 이메일 공지</td>
              </tr>

              <tr>
                <th class="subject">
                  캠프기간
                </th>
                <td class="description" colspan="2">
                  <time datetime="2024-12-26">2024년 12월 26일</time> - <time datetime="2025-01-26">2025년 1월 26일</time>
                </td>
              </tr>
              <tr>
                <th class="subject">
                  장소
                </th>
                <td colspan="2" class="description">
                  대전 KAIST
                </td>
              </tr>
              <tr>
                <th
                  rowspan="4"
                  class="subject">
                  참가비용
                </th>
                <th class="description">
                  수강료
                </th>
                <td class="description">
                  5만원(1학점)
                </td>
              </tr>
              <tr>
                <th class="description">운영비</th>
                <td class="description">
                  5만원
                </td>
              </tr>
              <tr>
                <th class="description">기숙사비</th>
                <td class="description">
                  KAIST 재학생은 학교 기준에 따름<br>
                  KAIST 휴학생 및 타 대학생은 35만원 내외
                </td>
              </tr>
              <tr>
                <th class="description">식비</th>
                <td class="description">본인 부담</td>
              </tr>
              <tr>
                <th class="subject">지원사항</th>
                <td colspan="2">24시간 전용 실습실, 개인별 모니터 1대, 공기계폰(2~3인당 1대)</td>
              </tr>
              <tr>
                <th class="subject">참가생 준비사항</th>
                <td colspan="2">노트북은 개인 지참, OS 종류(윈도우,맥)는 관계 없음<br>최소사양: 윈도우10 64bit, MacOS 10.14, RAM 8G, CPU i5 이상 /<br> 권장사양: RAM 16G 이상</td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </section>
    <section class="container announce">
      <div class="columns guide">
        <h2 class="column title is-3 has-text-right-tablet">지원서 작성<br class="is-hidden-mobile"> 가이드</h2>
        <div class="column is-6">
          <p>지원서 항목은 인적 사항, 대학 전공, 대학 생활, 기타의 4개 섹션으로 구성됩니다.</p>
          <p>몰입캠프의 성공 요인은 캠프 구성원들의 <u><b>'공통성'과 '다양성'</b></u>을 균형 있게 유지하는 데 있었습니다. 다양한 구성원이 모여 협력할 때 빠르게 학습하고 성장할 수 있기 때문입니다.</p>
          <p>'공통성'은 태도, 의지, 열정, 협업 능력 등의 요소를 의미하며, '다양성'은 학번, 성별, 활동 경험, 개발 역량, 자라온 환경 등과 관련이 있습니다. 구성원들의 다양한 배경을 중요하게 여기지만, 기본적으로 2~3학년 수준의 전산학과 기본 과목을 이수한 학생들이 다수 구성되기를 희망합니다.</p>
          <p>입력하신 정보를 기준으로 서류 심사가 진행되므로, 항목이 많더라도 지원자분을 잘 파악할 수 있도록 성실하게 작성해 주시기 바랍니다.</p>
          <p>또한, 몰입캠프 참가자로 선정된 후 취소할 경우, 추후 재지원 시 선발 우선순위가 크게 낮아질 수 있습니다.</p>
          <p><u><b>Internet Explorer에서 강의 계획서 및 지원서가 열리지 않을 수 있으므로, Chrome, Safari 등 다른 브라우저를 이용해 주세요.</b></u></p>
          <p>지원서 링크: https://forms.gle/K8HL5jvFDV3oYRFv8</p>
          <br>
          <a href="https://forms.gle/K8HL5jvFDV3oYRFv8" target="_blank" class="button is-primary is-large">지원서 작성</a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: '지원하기 | 몰입캠프',
    meta: [
      {
        name: 'description',
        content: '지원서 항목은 인적 사항, 대학 전공, 대학 생활, 기타의 4개 섹션으로 구성됩니다. 몰입캠프를 진행해 오면서, 캠프의 성공 요인은 학생들의 \'공통성\'과 \'다양성\'의 균형을 적절히 맞추는 것에 있었습니다. 이런 구성원이 모여 서로 협력하며 빠르게 학습하고 성장할 수 있습니다.'
      }
    ]
  }
}
</script>

<style lang="scss" scoped>
.apply {
  margin-top: 52px;
}
.apply-info-table {
  margin: 4rem auto;
  @media screen and (max-width: 768px) {
    padding: 1.5rem 0.75rem;
  }
  .title {
    padding: 1.25rem 1.5rem;
    color: black;
  }
  .table {
    margin-left: -0.75rem;
  }
}

.description {
  font-weight: normal;
}

.announce {
  margin: 4rem auto;
  @media screen and (max-width: 768px) {
    padding: 1.5rem;
  }
  .title {
    padding-right: 2.5rem;
  }
  .guide {
    p {
      margin-bottom: 1rem;
    }
    ol {
      margin-bottom: 1rem;
    }
  }
}
</style>
