<template>
  <div class="faq">
    <section class="hero is-dark is-medium">
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <h1 class="title column is-offset-3">
              FAQ
            </h1>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container qna">
        <div class="columns">
          <div class="column is-offset-3 is-7 is-text">
            <h3>지원 관련</h3>
            <h4>MOU를 맺지 않은 대학에서 지원이 가능한가요?</h4>
            <p>KAIST와 MOU 되어있지 않은 학교는 지원 대상에 포함되지 않습니다. 계절학기 학점교류 과목으로, KAIST와  MOU를 맺은 국내 및 해외 대학생을 대상으로 지원을 받습니다.</p>

            <h4>휴학생 또는 졸업학기인데 지원이 가능한가요? (KAIST 및 타 대학 모두 해당)</h4>
            <p>휴학생이나 졸업학기는 수강신청이 불가능하여, 수강신청하지 않고 학점 부여 없이 청강생으로 지원 가능합니다. 지원은 홈페이지의 '지원하기'에서 동일하게 지원 가능하고, 따로 수강신청 절차는 없습니다. 캠프의 모든 활동은 일반 참가생과 동일하지만, 성적 발급은 되지않습니다. 비용은 청강료 5만원과 기숙사비(35만원 내외)가 있습니다.</p>

            <h4>캠프 초기에 1~2일 정도 빠지게 되는데 지원 가능한가요?</h4>
            <p>몰입캠프의 기본 취지는 자율적 몰입입니다. 팀원과 협업하여 프로젝트를 해야 하기 때문에 일요일을 제외하고는 가급적 빠지지 않는 것을 권고합니다. 또한 몰입캠프는 초기가 매우 중요하기 때문에 초기에 1~2일을 빠지게 된다면, 캠프를 따라가기 힘들 수도 있음을 말씀드립니다.</p>

            <h4>캠프 중간에 2~3일 정도 빠지게 되는데 지원 가능한가요?</h4>
            <p>몰입캠프는 팀워크로 진행되는 프로그래밍 캠프입니다.  캠프 기간 내에 이틀 이상 빠지게 된다면 팀원에게 지장을 주게 되므로 가급적 빠지지 않는 것을 권고합니다. 혹시라도 빠지게 된다면 사전에 운영진과 협의가 필요합니다.</p>

            <h4>앱 개발 경험이 없는데 지원 가능한가요?</h4>
            <p>앱 개발 경험만 가지고 참가 여부를 판단하지 않기 때문에 앱 개발 경험이 없어도 지원 가능합니다.</p>

            <h4>프로그래밍 능력이 어느 정도 필요한가요?</h4>
            <p>프로그래밍 능력은 (계량화가 쉽지 않지만) 컴퓨터 전공 2학년 수준 이상이어야 몰입캠프 효과가 극대화됩니다. 다만, 프로그래밍 능력만으로 참가자를 선정하지는 않습니다.</p>

            <h4>개별 연구와 본 캠프를 병행 가능한가요?</h4>
            <p>4~5주 동안 일요일을 제외하고 대부분의 시간을 몰입캠프에 활용할 수 있어야 합니다. 따라서 개별 연구와 병행하기는 어렵습니다.</p>

            <h4>학점은 어떻게 부여되나요?</h4>
            <p>교과목이고, 부여 학점은 1학점이며, Pass/Fail로 부여됩니다.</p>
            
            <h4>모집 마감 후에 추가 지원 가능한가요?</h4>
            <p>모집 마감 후에는 추가 지원은 받지 않습니다. 모집 기간이 지났다면, 다음 학기 개설 시에 지원해 주세요.</p>

            <h4>기존에 몰입캠프에 참가했던 학생이 재수강 가능한가요?</h4>
            <p>재수강은 불가능합니다.</p>

            <h4>지원서 작성 시, 사진 또는 동영상 이름을 잘못 제출하였는데 어떻게 하나요?</h4>
            <p>이미 제출하였다면, 수정하지 않아도 무방합니다.</p>

            <h3>참가 관련</h3>
            <h4>참가생의 경우,  본인이 불참하게 된다면 공석에 친구 추천 가능한가요?</h4>
            <p>예비후보자를 미리 선정하기 때문에, 본인의 공석에 다른 사람을 추천받기는 어렵습니다.</p>

            <h4>불참자가 발생하여, 공석이 생긴다면 참가 가능한가요?</h4>
            <p>불참자에 대비하여, 예비후보자를 미리 선정하고, 공석은 예비후보자에서 채워지기 때문에, 추가 참가는 없습니다. 예비후보자는 참가생 결과 발표 시 같이 공지됩니다.</p>

            <h4>탈락 이유에 대해서 피드백 받을 수 있나요?</h4>
            <p>선발함에 있어, 개개인의 합격 여부보다는 다양한 요소들(*)을 고려하여 그룹(분반)을 구성하는데 좀 더 초점이 있습니다. 따라서, 개인별 탈락 이유에 관해서 따로 피드백 드릴 사항은 없습니다. (*) 학번, 성별, 병역 여부, 활동 경험, 수업 진도, 개발 경험 등등</p>

            <h4>몰입캠프 참가자로 선정된 후, 취소 시에 불이익이 있나요?</h4>
            <P>몰입캠프 참가자로 선정된 후 취소 시에는, 추후 재지원 시 선발 순위가 많이 낮아집니다.</p>
            
            <h3>기숙사 관련</h3>
            <h4>기숙사 입사 기간은 어떻게 되나요?</h4>
            <p>캠프 당일 오전부터 캠프 종강 2~3일 후까지 이용 가능합니다.</p>

            <h4>기숙사에 꼭 입사해야 되나요?</h4>
            <p>몰입캠프 특성상 기숙사 입사가 원칙입니다. 몰입하여 프로그래밍을 하게 되면, 기숙사까지도 가기 싫어질 수도 있습니다. KAIST 학생들의 경우, 학교 근처에서 자취하는 경우가  있는데, 해당 경우에는 몰입캠프 지원서에 반드시 밝혀주셔야 합니다.</p>

            <h4>기숙사에 짐을 미리 보내 놓을 수 있나요?</h4>
            <p>기숙사 규정 상 분실의 위험이 있어, 짐을 미리 보내는 것은 불가능합니다.</p>
          
            <h3>기타</h3>
            <h4>장병규 의장님은 왜 몰입캠프를 시작하셨나요?</h4>
            <p>장병규 의장님은 본인이 KAIST 학부생일 때 유관 동아리와 주변 환경의 영향으로 인하여 
                프로그래밍 아르바이트 및 집중적인 프로그래밍 활동 등으로 크게 성장한 경험을 가지고 있습니다. 
                후학들에게 해당 경험을 전하고 싶은 마음으로 몰입캠프를 운영하셨습니다.</p>

            <p>2023년 여름학기부터는 이 뜻을 이어받아 카이스트 학생들이 직접 몰입캠프를 기획하고 운영하고 있습니다.</p>

            <h4>청강생의 경우, 수료증 발급이 가능한가요?</h4>
            <p>수료증은 따로 발급되지 않습니다.</p>

            <h4>몰입캠프에 개인 데스크톱을 가지고 가도 되나요?</h4>
            <p>네, 가능합니다.</p>

            <h4>개인 노트북을 지참해야 하나요? 최소 사양은 무엇인가요?</h4>
            <p>캠프 기간 동안 실습에 사용할 노트북은 개인 지참입니다. OS종류(윈도우,맥)는 관계 없습니다. 최소사양은 윈도우10 64bit, MacOS10.14, RAM 8G, CPU i5이상, 권장사양은 RAM 16G 이상입니다.</p>

          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'FAQ | 몰입캠프'
  }
}
</script>

<style lang="scss" scoped>
.qna.container {
  margin-bottom: 6rem;
  @media screen and (max-width: 768px) {
    padding: 0 1.5rem;
  }
  .is-text {
    max-width: 45rem;
    line-height: 1.6;
    h3 {
      font-size: 1.5rem;
      font-weight: bold;
      margin: 4rem 0 0.75rem;
    }
    h4 {
      font-weight: bold;
      margin-bottom: 0.25rem;
    }
    p {
      margin-bottom: 1rem;
    }
  }
}
</style>
